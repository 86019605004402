/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const PRODUCT_IN_STOCK = 'IN_STOCK';

export const PRODUCT_OUT_OF_STOCK = 'OUT_OF_STOCK';
